<template>
  <div class="layout-padding row q-col-gutter-sm">
    <m-group-title v-if="resource.attributes.content_config.onwardtravel" label="Onward Travel" />
    <div v-if="resource.attributes.content_config.onwardtravel" class="col-xs-12">
      <q-card class="bg-white no-margin no-border">
        <q-card-section>
          <q-input
            v-model="resource.attributes.content_config.onwardtravel.tolerance_in_percent"
            label="Percentage tolerance"
            hint="Increases the distance calculations for the content for anyone in the organisation by a percentage to account for 'dead miles'"
          />
        </q-card-section>
      </q-card>
    </div>

    <q-page-sticky position="bottom-right" :offset="[30, 30]">
      <q-btn
        round
        size="lg"
        color="primary"
        icon="save"
        @click="saveOrg"
      />
    </q-page-sticky>
  </div>
</template>

<script>
import _ from 'lodash'
import { MGroupTitle } from 'components/'

export default {
  components: { MGroupTitle },
  data () {
    return {
      resource: null
    }
  },
  async beforeMount () {
    this.resource = await this.$store.getters['organisations/getOrgForm'](this.$route.params.organisation)
  },
  methods: {
    saveOrg () {
      const resource = _.cloneDeep(this.resource)
      return this.$store.dispatch('organisations/updateOrg', {
        org: resource
      }).then(() => {
        this.resource = this.$store.getters['organisations/getOrgForm'](this.$route.params.organisation)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.tariff-header
  margin-top 15px
.add-tariff
  margin-top 5px
  margin-left 10px
</style>
