<template>
  <div class="travel-type-item" :class="{selected}">
    <div>
      <m-travel-icon :type="type" color="white" :bg-color="getTravelContentHex(type)" size="36px" circle />
    </div>
    <div>
      {{ $tc(`content_type.${type}`, 2) }}
    </div>
    <div style="display: flex;">
      <i v-if="enabled" class="fas fa-check text-positive" />
      <i v-else class="fas fa-times text-negative" />
      <i v-if="priceThresholdSet" class="fas fa-coins text-primary" />
    </div>
  </div>
</template>
<script>
import { MTravelIcon } from 'components/'
import travelContents from 'mixins/travelContents'
export default {
  components: {
    MTravelIcon
  },
  mixins: [travelContents],
  props: {
    type: {
      type: String,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    enabled: {
      type: Boolean,
      default: false
    },
    priceThresholdSet: {
      type: Boolean,
      default: false
    }
  }
}
</script>
