<template>
  <q-menu v-bind="$attrs">
    <q-list>
      <q-item v-close-popup clickable @click="$emit('peek')">
        <q-item-section>Profile</q-item-section>
      </q-item>
      <q-separator />
      <template v-if="hasPermission('can.apply.organisation.brand')">
        <q-item v-close-popup clickable @click="$emit('editBranding')">
          <q-item-section>Edit Branding</q-item-section>
        </q-item>
        <q-separator />
      </template>
      <template v-if="hasPermission('organisation.travelpolicy.update')">
        <q-item v-close-popup clickable @click="$emit('editTransportBudget')">
          <q-item-section>Edit Transport Budget</q-item-section>
        </q-item>
        <q-separator />
      </template>
      <template v-if="hasPermission('feature.mfd1.1071.bookingauth.config') || isDevelopment">
        <q-item v-close-popup clickable @click="$emit('editBookingAuthConfig')">
          <q-item-section>Edit Booking Auth Config</q-item-section>
        </q-item>
        <q-separator />
      </template>
      <q-item v-for="menuItem in menuItems" :key="prepend + menuItem.tab" v-close-popup clickable @click="$emit('view', menuItem.tab)">
        <q-item-section>{{ menuItem.label }}</q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</template>
<script>
import authentication from 'mixins/authentication'
export default {
  mixins: [authentication],
  props: ['item', 'menuItems'],
  computed: {
    prepend () {
      return Object.keys(this.$attrs).includes('context-menu') ? 'c' : ''
    },
    isDevelopment () {
      return process.env.NODE_ENV === 'development'
    }
  }
}
</script>
