<template>
  <div class="index-list">
    <q-input
      v-model="search"
      :placeholder="$t('filter_by_member')"
      :disable="loading"
      outlined
      class="q-px-xl q-my-md"
    >
      <template v-slot:prepend>
        <q-icon name="search" />
      </template>
    </q-input>
    <m-infinite-scroll
      ref="infScroll"
      :load="get"
      :page="page"
      :pagination="pagination"
      icon="person"
      resource="members"
    >
      <div
        v-for="member in members"
        :key="member.username"
        class="row-item"
      >
        <member-card
          :member="member"
          org
          @change-role="changeRole"
        />
      </div>
    </m-infinite-scroll>

    <q-page-sticky position="bottom-right" :offset="[20, 20]">
      <q-fab
        color="green"
        icon="fas fa-user-plus"
        direction="left"
        :title="$tc('create_new_member', 2)"
      >
        <q-fab-action
          color="primary"
          icon="fas fa-plus"
          @click="createModal = true"
        />

        <q-fab-action
          color="primary"
          icon="fas fa-file-upload"
          @click="openUploadModal(false)"
        />
      </q-fab>
    </q-page-sticky>

    <q-page-sticky position="bottom-right" :offset="[20, 90]">
      <q-fab
        color="primary"
        icon="fas fa-users-cog"
        direction="left"
        :title="$tc('edit_member', 2)"
      >
        <q-fab-action
          color="primary"
          icon="fas fa-file-upload"
          @click="openUploadModal(true)"
        />
      </q-fab>
    </q-page-sticky>

    <q-page-sticky position="bottom-right" :offset="[20, 160]">
      <q-fab
        color="primary"
        icon="fas fa-file-download"
        direction="left"
        :title="$t('download_member')"
      >
        <q-fab-action
          color="primary"
          icon="fas fa-file-download"
          @click="downloadMembers"
        />
      </q-fab>
    </q-page-sticky>

    <member-create
      v-model="createModal"
      :organisation="$route.params.organisation"
      @created="clear"
      @switch-to-upload="switchToUpload"
    />
    <member-upload
      v-model="uploadModal"
      :organisation="$route.params.organisation"
      :bulk-modify="bulkModify"
      @created="clear"
    />
    <m-change-role
      v-model="changeRoleModal.show"
      :member="changeRoleModal.member"
      :organisation="$attrs.resource"
    />
  </div>
</template>

<script>
import memberCard from 'pages/members/list/member-card'
import memberCreate from '../../members/list/member-create'
import memberUpload from '../../members/list/member-upload'
import { MInfiniteScroll, MChangeRole } from 'components/'
import { debounce } from 'lodash'
import { downloadMembersCSV } from 'api/members'
import { handleErrors } from 'utils/utils'

export default {
  components: {
    memberCard,
    memberCreate,
    memberUpload,
    MInfiniteScroll,
    MChangeRole
  },
  data () {
    return {
      search: null,
      changeRoleModal: {
        show: false,
        member: {}
      },
      createModal: false,
      uploadModal: false,
      bulkModify: false,
      page: null,
      pagination: null,
      members: [],
      columns: ['Name', 'Email', 'Status'],
      loading: false
    }
  },
  watch: {
    search: {
      handler: debounce(function (val, oldVal) {
        if (!val?.length && !oldVal) return
        this.clear()
      }, 750),
      immediate: true
    }
  },
  created () {
    this.$store.dispatch('members/reset')
  },
  methods: {
    clear () {
      this.page = 0
      this.members = []
    },
    changeRole (member) {
      this.changeRoleModal.member = member
      this.changeRoleModal.show = true
    },
    get (page) {
      this.page = page
      const params = { page }
      if (this.search) params.search = this.search
      this.loading = true
      return this.$store.dispatch('members/loadUsers', {
        orgId: this.$route.params.organisation,
        params
      })
        .then(response => {
          this.members = this.members.concat(response.data)
          this.pagination = response.meta.pagination
        })
        .finally(() => {
          this.loading = false
        })
    },
    openUploadModal (bulkModify) {
      this.uploadModal = true
      this.bulkModify = bulkModify
    },
    switchToUpload () {
      this.bulkModify = false
      this.uploadModal = true
      this.createModal = false
    },
    async downloadMembers () {
      try {
        // Downloading text and appending is enough to create the CSV
        const text = await downloadMembersCSV(this.$route.params.organisation)
        const memberCSV = 'data:text/csv;charset=utf-8,' + text
        // But to give it a specific name, we turn it into a link and then click it
        const link = document.createElement('a')
        link.setAttribute('href', memberCSV)
        link.setAttribute('download', `${this.$route.params.organisation}_members.csv`)
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.log(err)
        handleErrors(err)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.index-list
  overflow auto
  margin auto
  margin-bottom 64px
  .row-item:first-child
    margin-top 16px

  @media (min-width 768px)
    padding 0 3rem

.row-item
  width 100%
  // @media (min-width 768px)
  //   width 75%
</style>
