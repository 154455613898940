<template>
  <m-infinite-activity
    :getter="load"
    height="calc(100vh - 316px)"
  />
</template>

<script>
import { activities as getOrganisationActivity } from 'api/organisations'
import { MInfiniteActivity } from 'components/'

export default {
  components: { MInfiniteActivity },
  methods: {
    async load (filters) {
      return getOrganisationActivity(this.$route.params.organisation, filters)
    }
  }
}
</script>
