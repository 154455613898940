<template>
  <div class="create-modal-wrapper">
    <q-btn
      fab
      class="absolute btn-create-resource"
      color="positive"
      icon="add"
      @click="open"
    />
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'OrganisationCreate',
  methods: {
    create (name) {
      this.$store.dispatch('organisations/createOrg', {
        name
      }).then(org => {
        this.$router.push(`/organisations/${org.slug}`)
      })
    },
    open () {
      this.$q.dialog({
        title: 'Add new organisation',
        message: 'Name',
        prompt: {
          model: '',
          type: 'text' // optional
        },
        ok: 'Create',
        color: 'secondary'
      }).onOk(data => {
        this.create(data)
      })
    },
    close () {
      this.clear()
      this.show = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.btn-create-resource
  position fixed
  bottom: 15px
  right: 15px
  @media (min-width: 768px)
    position absolute
    right 50px
    top 175px
    bottom auto
</style>
