<template>
  <div v-if="resource" class="layout-padding row q-col-gutter-sm">
    <m-group-title label="Company Info" />
    <div class="col-xs-12">
      <q-card class="bg-white no-margin no-border">
        <q-card-section>
          <q-input
            v-model="resource.name"
            label="Company name"
          />

          <q-input
            v-model="resource.attributes.legal_name"
            label="Legal Name"
          />

          <q-input
            v-model="resource.attributes.telephone_number"
            type="tel"
            label="Telephone Number"
          />

          <q-input
            v-model="resource.attributes.email_address"
            type="email"
            label="Email Address"
          />

          <q-input
            v-model="resource.attributes.vat_number"
            label="VAT Number"
          />

          <q-input
            v-model="resource.attributes.company_number"
            label="Company Number"
          />

          <q-select
            v-model="resource.attributes.number_of_employees"
            :options="employees"
            label="Number Of Employees"
            emit-value
          />

          <q-input
            v-model="resource.attributes.account_reference"
            label="Account reference number"
          />

          <q-checkbox
            v-model="resource.attributes.requester_confirmation_emails"
            label="Requesters receive booking confirmation emails"
          />
        </q-card-section>
      </q-card>
    </div>
    <m-group-title label="Company Contact Info" />
    <div class="col-xs-12">
      <q-card class="bg-white no-margin no-border">
        <q-card-section>
          <q-input
            v-model="resource.attributes.key_contact_name"
            label="Key Contact Name"
          />

          <q-input
            v-model="resource.attributes.key_contact_phone_number"
            type="tel"
            label="Key Contact Phone Number"
          />

          <q-input
            v-model="resource.attributes.key_contact_email_address"
            type="email"
            label="Key Contact Email Address"
          />

          <q-input
            v-model="resource.attributes.billing_contact_name"
            label="Billing Contact Name"
          />

          <q-input
            v-model="resource.attributes.billing_contact_phone_number"
            type="tel"
            label="Billing Contact Phone Number"
          />

          <q-input
            v-model="resource.attributes.billing_contact_email_address"
            type="email"
            label="Billing Contact Email Address"
          />
        </q-card-section>
      </q-card>
    </div>
    <m-group-title label="FOD Rental Info" />
    <div class="col-xs-12">
      <q-card class="bg-white no-margin no-border">
        <q-card-section>
          <q-input
            v-model="resource.attributes.vehicle_hire_requestor_id"
            label="Vehicle hire requestor ID"
          />

          <q-input
            v-model="resource.gts_business_id"
            label="GT Suite Business ID"
            :hint="gtsHint"
          >
            <q-btn
              color="primary"
              :label="$t('check')"
              class="q-mb-sm"
              @click="businessIdCheck(resource.gts_business_id)"
            />
          </q-input>

          <div v-if="showPopup" :class="['business-check-popup', { 'mini-side-bar': isMiniSideBar }]">
            <q-card>
              <q-card-section>
                <p v-html="popupMessage" />
              </q-card-section>
              <q-card-actions align="right">
                <q-btn label="Close" color="primary" @click="showPopup = false" />
              </q-card-actions>
            </q-card>
          </div>

          <div class="row q-col-gutter-sm tariff-header">
            <m-group-title label="Available Tariffs" columns="null" />
            <q-btn round dense icon="add" class="add-tariff" @click="addTariff" />
          </div>
          <tariff-detail
            v-for="(t, i) in resource.attributes.vehicle_hire_tariffs"
            :key="i"
            v-model="resource.attributes.vehicle_hire_tariffs[i]"
            @removeTariff="() => removeTariff(i)"
          />
          <tariff-new
            v-for="(t, i) in unsavedTariffs"
            :key="i + resource.attributes.vehicle_hire_tariffs.length"
            v-model="unsavedTariffs[i]"
            @removeTariff="() => removeUnsavedTariff(i)"
          />
        </q-card-section>
      </q-card>
    </div>
    <m-group-title label="Business Areas" />
    <div class="col-xs-12">
      <q-card class="bg-white no-margin no-border">
        <q-card-section>
          <div class="row q-col-gutter-sm tariff-header">
            <q-chip
              v-for="(businessArea, index) in resource.business_areas"
              :key="index"
              color="primary"
              text-color="white"
              removable
              @remove="removeBusinessArea(businessArea)"
            >
              {{ businessArea }}
            </q-chip>
          </div>
          <q-input
            v-for="(c, i) in unsavedBusinessAreas"
            :key="i + 1"
            v-model="unsavedBusinessAreas[i]"
          />
          <q-btn round dense icon="add" class="add-tariff" @click="addBusinessArea" />
        </q-card-section>
      </q-card>
    </div>
    <m-group-title label="Cost Centres" />
    <div class="col-xs-12">
      <q-card class="bg-white no-margin no-border">
        <q-card-section>
          <div class="row q-col-gutter-sm tariff-header">
            <q-chip
              v-for="(costCentre, index) in resource.cost_centres"
              :key="index"
              color="primary"
              text-color="white"
              removable
              @remove="removeCostCentre(costCentre)"
            >
              {{ costCentre }}
            </q-chip>
          </div>
          <q-input
            v-for="(c, i) in unsavedCostCentres"
            :key="i + 1"
            v-model="unsavedCostCentres[i]"
          />
          <q-btn round dense icon="add" class="add-tariff" @click="addCostCentre" />
        </q-card-section>
      </q-card>
    </div>
    <m-group-title v-if="resource.attributes.travel_policy.hotel" label="Hotelzon Info" />
    <div v-if="resource.attributes.travel_policy.hotel" class="col-xs-12">
      <q-card class="bg-white no-margin no-border">
        <q-card-section>
          <q-input
            v-model="resource.attributes.hotelzon_target_company_id"
            label="Hotelzon Target Company ID"
          />
        </q-card-section>
        <q-card-section>
          <m-money-field v-model="resource.attributes.travel_policy.hotel.meal_allowance" :label="$t('meal_allowance')" />
        </q-card-section>
      </q-card>
    </div>
    <m-group-title label="Ridehailing Info" />
    <div class="col-xs-12">
      <q-card class="bg-white no-margin no-border">
        <q-card-section>
          <q-input
            v-model="resource.cmac_account_id"
            label="CMAC Account ID"
          />
        </q-card-section>
      </q-card>
    </div>
    <m-group-title label="Billing Address" />
    <div class="col-xs-12">
      <q-card class="bg-white no-margin no-border">
        <q-card-section>
          <q-input
            v-model="resource.attributes.addresses[0].line_one"
            label="Address Line One"
          />

          <q-input
            v-model="resource.attributes.addresses[0].line_two"
            label="Address Line Two"
          />

          <q-input
            v-model="resource.attributes.addresses[0].state"
            label="County"
          />

          <q-input
            v-model="resource.attributes.addresses[0].post_code"
            label="Post Code"
          />

          <q-select
            v-model="resource.attributes.addresses[0].country"
            :options="countriesList"
            filter
            label="Country"
            emit-value
          />
        </q-card-section>
      </q-card>
    </div>
    <m-group-title label="Invoicing" />
    <m-invoice-customiser v-model="resource.attributes.invoice_custom_content" />

    <q-page-sticky position="bottom-right" :offset="[30, 30]">
      <q-btn
        round
        size="lg"
        color="primary"
        icon="save"
        @click="saveOrg"
      />
    </q-page-sticky>
  </div>
</template>

<script>
import _ from 'lodash'
import loading from 'utils/loading'
import countries from 'store/countries'
import tariffDetail from './tariff-detail'
import tariffNew from './tariff-new'
import { MInvoiceCustomiser, MMoneyField, MGroupTitle } from 'components'
import { checkBusinessId } from '../../../api/organisations'
import { mapGetters } from 'vuex'
import i18n from 'i18n'
import store from 'store'
export default {
  components: {
    tariffDetail,
    tariffNew,
    MInvoiceCustomiser,
    MMoneyField,
    MGroupTitle
  },
  data () {
    return {
      showPopup: false,
      popupMessage: '',
      unsavedTariffs: [],
      defaultOrgTariff: '29587-29625',
      defaultOrgGtsBusinessId: 37624,
      defaultRetailGtsBusinessId: 37621,
      gtsHint: null,
      unsavedCostCentres: [],
      unsavedBusinessAreas: [],
      resource: null,
      invoiceInfoDialog: false,
      employees: [
        {
          label: 'Self-employed',
          value: '1'
        },
        {
          label: '1-10 employees',
          value: '1'
        },
        {
          label: '11-50 employees',
          value: '11-50'
        },
        {
          label: '51-200 employees',
          value: '51-200'
        },
        {
          label: '201-500 employees',
          value: '201-500'
        },
        {
          label: '501-1000 employees',
          value: '501-1000'
        },
        {
          label: '1001-5000 employees',
          value: '1001-5000'
        },
        {
          label: '5001-10,000 employees',
          value: '5001-10000'
        },
        {
          label: '10,001+ employees',
          value: '10000+'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ui: 'ui'
    }),
    countriesList () {
      return countries.map((c) => ({ label: c.en_short_name, value: c.en_short_name }))
    },
    isMiniSideBar () {
      return this.ui.quasar.mini.mode
    }
  },
  async beforeMount () {
    this.resource = await this.$store.getters['organisations/getOrgForm'](this.$route.params.organisation)
    this.gtsHint = `Determines legitimate business addresses for D&C. Can only be set if the organisation does not have tariff ${this.defaultOrgTariff} in the list below - either included or not`

    if (!this.resource.attributes.addresses.length) {
      this.resource.attributes = { ...this.resource.attributes, addresses: [{}] }
    }

    if (!this.resource.attributes.travel_policy) {
      this.resource.attributes = {
        ...this.resource.attributes,
        travel_policy: {
          hotel: {
            meal_allowance: 0
          }
        }
      }
    }
  },
  methods: {
    addAddress () {
      this.resource.attributes.addresses.unshift({})
    },
    async saveOrg () {
      const cmac_account_id = this.resource.cmac_account_id
      let resource = _.omit(_.cloneDeep(this.resource, 'cmac_account_id'))
      if (this.unsavedTariffs.length) {
        resource.attributes.vehicle_hire_tariffs = resource.attributes.vehicle_hire_tariffs.concat(this.unsavedTariffs)
        // If you try and add the default tariff, then nullify the GT business ID - it will infer a default as an override on the back-end regardless
        if (this.unsavedTariffs.some(t => t.code === this.defaultOrgTariff || t.label === this.defaultOrgTariff)) {
          resource.gts_business_id = null
        }
      }
      if ([this.defaultOrgGtsBusinessId, this.defaultRetailGtsBusinessId].includes(this.resource.gts_business_id)) {
        // Don't save the data if the value is either the default org or retail one
        resource.gts_business_id = null
      }
      if (this.unsavedCostCentres.length) {
        const filteredCostCentres = this.unsavedCostCentres.filter(val => val)
        resource.attributes.cost_centres = resource.cost_centres
          ? resource.cost_centres.concat(filteredCostCentres)
          : filteredCostCentres
      } else {
        resource.attributes.cost_centres = resource.cost_centres || []
      }
      if (this.unsavedBusinessAreas.length) {
        const filteredBusinessAreas = this.unsavedBusinessAreas.filter(val => val)
        resource.attributes.business_areas = resource.business_areas
          ? resource.business_areas.concat(filteredBusinessAreas)
          : filteredBusinessAreas
      } else {
        resource.attributes.business_areas = resource.business_areas || []
      }
      if (_.isEmpty(resource.attributes.addresses[0])) {
        resource.attributes.addresses = []
      }

      try {
        await this.$store.dispatch('organisations/updateRidehailingConfig', {
          orgId: resource.slug,
          config: { cmac_account_id: cmac_account_id || null }
        })
        await this.$store.dispatch('organisations/updateOrg', {
          org: resource
        })
        this.unsavedTariffs = []
        this.unsavedCostCentres = []
        this.unsavedBusinessAreas = []
        this.resource = this.$store.getters['organisations/getOrgForm'](this.$route.params.organisation)
        if (!this.resource.attributes.addresses.length) {
          this.resource.attributes = { ...this.resource.attributes, addresses: [{}] }
        }
      } catch (e) {
        console.log(e)
      }
    },
    addTariff () {
      this.unsavedTariffs.push({
        label: null,
        code: null
      })
    },
    removeTariff (index) {
      this.resource.attributes.vehicle_hire_tariffs.splice(index, 1)
    },
    removeUnsavedTariff (index) {
      this.unsavedTariffs.splice(index, 1)
    },
    addCostCentre () {
      this.unsavedCostCentres.push(null)
    },
    removeCostCentre (value) {
      this.resource.cost_centres.splice(this.resource.cost_centres.indexOf(value), 1)
    },
    removeUnsavedCostCentre (index) {
      this.unsavedCostCentres.splice(index, 1)
    },
    addBusinessArea () {
      this.unsavedBusinessAreas.push(null)
    },
    removeBusinessArea (value) {
      this.resource.business_areas.splice(this.resource.business_areas.indexOf(value), 1)
    },
    removeUnsavedBusinessArea (index) {
      this.unsavedBusinessAreas.splice(index, 1)
    },
    async businessIdCheck (businessId) {
      const partner = store.getters.partner

      loading.start({
        message: i18n.t('loading.checking.businessId'),
        partnerSlug: partner.slug,
        spinnerColor: partner.hex
      })

      try {
        const { data } = await checkBusinessId(businessId)
        this.popupMessage = `The Business ID provided is for <b style="font-weight:bold">${data.name}</b> - please double check that this is the organisation you are trying to update and amend if not correct.`
      } catch (error) {
        this.popupMessage = 'No business found for provided Business ID'
        console.error('Error:', error)
      } finally {
        this.showPopup = true
        loading.stop()
      }
    }
  }
}

</script>

<style lang="stylus" scoped>
.tariff-header
  margin-top 15px
.add-tariff
  margin-top 5px
  margin-left 10px
.business-check-popup
  position: fixed
  top: 50%
  left: calc(50% + 150px)
  transform:translate(-50%, -50%)
  z-index:100
.mini-side-bar {
  left: calc(50% + 50px)
}
</style>
