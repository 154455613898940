<template>
  <m-booking-card-list
    :bookings-org="$route.params.organisation"
    class="layout-padding"
  />
</template>

<script>
import { MBookingCardList } from 'components/'

export default {
  components: { MBookingCardList }
}
</script>
