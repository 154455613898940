<template>
  <q-card
    v-ripple
    :class="
      selected === organisation.slug
        ? 'bg-deep-purple-4 text-white shadow-3'
        : 'bg-white shadow-1'
    "
    class="cursor-pointer resource-card"
    @click.native="peek"
  >
    <context-menu
      :item="organisation"
      :menu-items="menuItems"
      context-menu
      touch-position
      @view="(tab) => view(tab)"
      @peek="peek"
      @editBranding="editBranding"
      @editTransportBudget="editTransportBudget"
      @editBookingAuthConfig="editBookingAuthConfig"
    />
    <q-card-section class="row">
      <div class="col-xs-4 col-sm-6 col-md-3 tcell">
        {{ organisation.name }}
      </div>
      <div class="gt-sm col-md-3 tcell">
        {{
          organisation.attributes && organisation.attributes.key_contact_name
        }}
      </div>
      <div class="gt-sm col-md-3 tcell">
        {{
          organisation.attributes && organisation.attributes.telephone_number
        }}
      </div>
      <div
        class="col-xs-8 col-sm-6 col-md-3 tcell small-hide row q-col-gutter-sm"
      >
        <div class="col-xs-6">
          <span>
            <q-chip
              :small="true"
              class="text-white"
              :color="organisation.is_blocked ? 'negative' : 'positive'"
            >
              {{ organisation.is_blocked ? 'Blocked' : 'Active' }}
            </q-chip>
          </span>
        </div>
      </div>
      <q-btn
        icon="more_vert"
        text-color="primary"
        class="invBtn absolute-right"
        unelevated
        @click.stop
      >
        <context-menu
          :item="organisation"
          :menu-items="menuItems"
          @view="(tab) => view(tab)"
          @peek="peek"
          @editBranding="editBranding"
          @editTransportBudget="editTransportBudget"
        />
      </q-btn>
    </q-card-section>
  </q-card>
</template>

<script>
import contextMenu from './context-menu.vue'
import authentication from 'mixins/authentication'
export default {
  components: { contextMenu },
  mixins: [authentication],
  props: {
    small: Boolean,
    organisation: {
      type: Object
    },
    selected: {
      type: String
    }
  },
  data () {
    return {
      menuItems: [
        {
          label: 'Members',
          tab: 'members'
        },
        {
          label: 'Bookings',
          tab: 'bookings'
        },
        {
          label: 'Pricing Schedule',
          tab: 'pricing'
        },
        {
          label: 'Notes',
          tab: 'notes'
        },
        {
          label: 'History',
          tab: 'history'
        }
      ]
    }
  },
  methods: {
    peek () {
      // FEATURE FLAG: MFD1-807
      if (!this.hasPermission('feature.mfd1.807.organisations.settings.use.peek')) {
        this.view('profile')
      } else {
        this.$router.push({ hash: '/organisation', query: { slug: this.organisation.slug } })
      }
    },
    view (tab) {
      this.$router.push(`/organisations/${this.organisation.slug}/${tab}`)
    },
    editBranding () {
      this.$router.push({ hash: '/organisation/branding', query: { slug: this.organisation.slug } })
    },
    editTransportBudget () {
      this.$router.push({ hash: '/organisation/transport-budget', query: { slug: this.organisation.slug } })
    },
    editBookingAuthConfig () {
      this.$router.push(`/organisations/${this.organisation.slug}/booking-auth`)
    }
  }
}
</script>

<style lang="stylus" scoped>
.tcell
  display flex
  align-items center
  justify-content flex-start

.avatar
  margin-right 15px

.member-menu
  position absolute
  top 0
  right 12px

.actions
  height 50px

.small-organisation-list
  .resource-card
    width 96%

  .small-hide
    display none

  .resource-card
    max-height 70px
</style>
