<template>
  <q-page v-if="org" class="q-mx-lg q-pt-lg">
    <m-group-title
      lowercase
      :label="$t('booking_authorisation.for_org', { org: org.name })"
      :sublabel="$t('booking_authorisation.explain_overview')"
    />

    <!-- Global toggle for booking authorisation on this organisation -->
    <q-card>
      <q-card-section>
        <q-list>
          <q-item dense>
            <q-item-section>
              <q-item-label>
                {{ $t('booking_authorisation.auth_required') }}
              </q-item-label>
              <q-item-label caption>
                {{ $t('booking_authorisation.explain_auth_required') }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-toggle v-model="bookingAuthEnabled" />
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
      <template
        v-if="bookingAuthEnabled && hasPermission('can.book.for.anyone')"
      >
        <q-separator />
        <q-card-section>
          <q-list separator>
            <q-item>
              <q-item-section>
                <q-item-label>
                  {{ $t('booking_authorisation.backoffice_bypass') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('booking_authorisation.explain_backoffice_bypass') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle v-model="middleofficeBypass" />
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label>
                  {{ $t('booking_authorisation.middleoffice_bypass') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('booking_authorisation.explain_middleoffice_bypass') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle v-model="backofficeBypass" />
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
      </template>
    </q-card>

    <m-group-title
      lowercase
      :label="$t('booking_authorisation.by_travel_types')"
      :sublabel="$t('booking_authorisation.explain_travel_types')"
    />
    <!-- Travel Type configuration for Booking Authorisations -->
    <div class="travel-type-configuration-grid">
      <div class="travel-type-list">
        <!-- list of travel types appear here for selection, as well as preview of quick settings -->
        <travel-type-item
          v-for="type in contentTypes"
          :key="`travel-type-item-${type}`"
          :type="type"
          :enabled="travelTypesApprovalID[type] !== undefined"
          :selected="type === selectedTravelType"
          @click.native="setSelectedTravelType(type)"
        />
      </div>
      <div class="travel-type-settings">
        <!-- Settings for selected travel type - enabled/disabled, minimum price threshold, etc -->

        <template v-if="selectedTravelType">
          <!-- Toggle whether authorisation is required for Travel Type -->
          <q-card>
            <q-card-section>
              <q-list>
                <q-item dense>
                  <q-item-section>
                    <q-item-label>
                      {{
                        $t('booking_authorisation.type_auth_required', {
                          type: $tc(`content_type.${selectedTravelType}`, 2),
                        })
                      }}
                    </q-item-label>
                    <q-item-label caption>
                      {{
                        $t('booking_authorisation.explain_type_auth_required')
                      }}
                    </q-item-label>
                  </q-item-section>
                  <q-item-section side>
                    <q-toggle v-model="travelTypeEnabled" />
                  </q-item-section>
                </q-item>
              </q-list>
            </q-card-section>
          </q-card>

          <!-- Minimum Price Threshold setting for Travel Type -->
          <q-card>
            <q-card-section>
              <q-list>
                <q-item dense>
                  <q-item-section>
                    <q-item-label>
                      {{ $t('booking_authorisation.minimum_price_threshold') }}
                    </q-item-label>
                    <div>
                      <q-input
                        v-model="minimumPriceThreshold"
                        type="number"
                        prefix="£"
                        :rules="[
                          (val) =>
                            val >= 0 || 'Please enter a value greater than 0',
                        ]"
                      />
                    </div>
                    <q-item-label caption>
                      {{
                        $t(
                          'booking_authorisation.explain_minimum_price_threshold'
                        )
                      }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-card-section>
          </q-card>
        </template>

        <q-spinner
          v-else-if="contentTypes.length"
          size="72px"
          color="primary"
        />

        <m-group-title v-else label="😬" :sublabel="$t('error.try_later')" />
      </div>
    </div>
    <q-btn
      :label="$t('save.save')"
      color="primary"
      class="float-right"
      :loading="loading"
      @click="save"
    />
  </q-page>
</template>
<script>
import Vue from 'vue'
import TravelTypeItem from './travel-type-item.vue'
import { MGroupTitle } from 'components/'
import { mapGetters } from 'vuex'
import authentication from 'mixins/authentication'
import { handleErrors } from 'utils/utils'
import travelContents from 'mixins/travelContents'
import { getContentApprovalList, enableContentApprovalType, disableContentApprovalType, getClauses, setContentMinimumPriceThreshold, updateContentMinimumPriceThreshold, removeContentMinimumPriceThreshold } from 'api/organisations'
export default {
  name: 'OrangisationsBookingAuth',
  components: {
    MGroupTitle,
    TravelTypeItem
  },
  mixins: [travelContents, authentication],
  data () {
    return {
      selectedTravelType: null,
      travelTypesApprovalID: {},
      clauses: {}, // format: { contentType: 'taxi', limit: 100, id: 1 }
      loading: false,
      minimumPriceThreshold: 0,
      pageHasChanges: false
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    bookingAuthEnabled: {
      get () {
        return this.org?.attributes.authorisation_enabled || false
      },
      set (val) {
        this.$store.dispatch('organisations/setAuthorisationRequired', {
          org: this.orgSlug,
          enabled: val
        })
          .finally(() => {
            this.refreshOrganisation(this.orgSlug)
          })
      }
    },
    middleofficeBypass: {
      get () {
        return this.org?.attributes.authorisation_middleoffice_bypass || false
      },
      set (val) {
        this.$store.dispatch('organisations/setBypass', {
          org: this.orgSlug,
          role: 'middleoffice',
          enabled: val
        })
          .finally(() => {
            this.refreshOrganisation(this.orgSlug)
          })
      }
    },
    backofficeBypass: {
      get () {
        return this.org?.attributes.authorisation_backoffice_bypass || false
      },
      set (val) {
        this.$store.dispatch('organisations/setBypass', {
          org: this.orgSlug,
          role: 'backoffice',
          enabled: val
        })
          .finally(() => {
            this.refreshOrganisation(this.orgSlug)
          })
      }
    },
    travelTypeEnabled: {
      get () {
        return this.travelTypesApprovalID[this.selectedTravelType] !== undefined
      },
      set (val) {
        const toggleMethod = val ? enableContentApprovalType : disableContentApprovalType

        this.loading = true

        toggleMethod(this.orgSlug, val ? this.selectedTravelType : this.travelTypesApprovalID[this.selectedTravelType])
          .then(res => {
            Vue.set(this.travelTypesApprovalID, this.selectedTravelType, val ? res.data.id : undefined)
          })
          .catch(err => {
            handleErrors(err)

            this.getFreshContentApprovalList()
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    orgSlug () {
      return this.$route.params.organisation
    },
    org () {
      return this.$store.getters['organisations/getOrg'](this.orgSlug) || null
    },
    contentTypes () {
      // List of disallowed booking/content types for booking authentication
      const disallowedTypes = [
        'onwardtravel' // Prevent RAC from disabling things they shouldn't.
      ]
      return this.partner.bookables
        .filter(type => !disallowedTypes.includes(type)) // Remove disallowed
        .map(type => this.getTravelPreferenceName(type)) // Use Travel preference name (The API preferred name)
    }
  },
  watch: {
    selectedTravelType (val) {
      this.minimumPriceThreshold = this.clauses[val]?.limit || 0
    },
    minimumPriceThreshold (val) {
      this.pageHasChanges = true
    }
  },
  async created () {
    this.refreshOrganisation(this.orgSlug)
    this.selectedTravelType = this.contentTypes[0]

    this.getFreshContentApprovalList()
  },
  methods: {
    setSelectedTravelType (type) {
      if (!this.pageHasChanges) this.selectedTravelType = type
      else {
        this.$q.dialog({
          title: this.$t('booking_authorisation.unsaved_changes.title'),
          message: this.$t('booking_authorisation.unsaved_changes.message'),
          cancel: true,
          persistent: true
        }).onOk(() => {
          this.selectedTravelType = type
          // Disable page changes, must come after selectedTravelType is set and on next tick otherwise
          // the watchers will fire and set pageHasChanges to true again...
          this.$nextTick(() => {
            this.pageHasChanges = false
          })
        })
      }
    },
    refreshOrganisation (orgId) {
      this.$store.dispatch('organisations/loadOrg', { orgId })
    },
    getFreshContentApprovalList () {
      this.travelTypesApprovalID = {}

      getContentApprovalList(this.orgSlug)
        .then(res => {
          res.data.forEach(entry => {
            Vue.set(this.travelTypesApprovalID, entry.content.name, entry.id)
          })
        })
        .catch(err => {
          handleErrors(err)
        })

      getClauses(this.orgSlug)
        .then(clauses => {
          clauses.forEach(clause => {
            Vue.set(this.clauses, clause.content_slug, clause)
          })
        })
        .catch(err => {
          handleErrors(err)
        })
    },
    setContentTypeMinimumPriceThreshold (type, threshold = 0) {
      const useUpdateEndpoint = this.clauses[type]?.id
      const useDeleteEndpoint = threshold <= 0

      this.loading = true

      if (useUpdateEndpoint) {
        updateContentMinimumPriceThreshold(this.orgSlug, this.clauses[type].id, threshold)
          .then(res => {
            Vue.set(this.clauses, type, res.data)
            this.pageHasChanges = false
          })
          .catch(err => {
            handleErrors(err)

            this.getFreshContentApprovalList()
          })
          .finally(() => {
            this.loading = false
          })
      } else if (useDeleteEndpoint) {
        removeContentMinimumPriceThreshold(this.orgSlug, this.clauses[type].id)
          .then(() => {
            Vue.delete(this.clauses, type)
            this.pageHasChanges = false
          })
          .catch(err => {
            handleErrors(err)

            this.getFreshContentApprovalList()
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        setContentMinimumPriceThreshold(this.orgSlug, type, threshold)
          .then(res => {
            Vue.set(this.clauses, type, {
              contentType: type,
              limit: threshold,
              id: res.data.id
            })
            this.pageHasChanges = false
          })
          .catch(err => {
            handleErrors(err)

            this.getFreshContentApprovalList()
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    save () {
      this.loading = true
      this.pageHasChanges = false

      this.setContentTypeMinimumPriceThreshold(this.selectedTravelType, this.minimumPriceThreshold)
    }
  }
}
</script>
<style lang="stylus" scoped>
.travel-type-configuration-grid
  display grid
  grid-template-columns max-content 1fr
  grid-gap .5rem
  grid-template-areas 'travel-type-list travel-type-settings'
  background-color #fafafa
  box-shadow inset 0 0 16px 4px rgba(0, 0, 0, .02)
  margin 16px 0
  border-radius 8px
  overflow auto
  border 1px solid rgba(0, 0, 0, .125)

.travel-type-list
  grid-area travel-type-list
  display grid
  grid-template-columns 1fr
  background-color #f1f1f1
  box-shadow 0 4px 4px 0 rgba(0, 0, 0, .25)

.travel-type-item
  display grid
  grid-template-columns auto 1fr min-content
  grid-gap 1rem
  padding 12px
  background-color #fff
  box-shadow 0 0 4px 0 rgba(0, 0, 0, .25)
  cursor pointer
  transition width .2s ease-in-out

  &.selected
    width 110%
    position relative
    background-color lighten($primary, 95%)
    border-bottom 1px solid alpha($primary, .25)
    border-radius 0 1rem 1rem 0
    cursor default
    border-right 1px solid rgba(0, 0, 0, .125)
    border-bottom 1px solid rgba(0, 0, 0, .125)
    transition width .2s ease-in-out

    &:nth-child(1)
      border-radius 0 0 1rem 0

    &:last-child
      border-radius 0 1rem 0 0

.travel-type-settings
  padding 16px 32px
</style>
