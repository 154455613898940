<template>
  <div class="layout-padding q-ma-md container">
    <div>
      <m-group-title label="Vehicle Groups" />
      <q-item-label caption style="margin-top: 0.5rem;">
        Enable/disable vehicle groups for onward travel searches
      </q-item-label>

      <q-card bordered class="q-pa-md flex-card" style="margin-top: 1.5rem;">
        <div class="row items-center no-grow q-gutter-md">
          <div v-for="(vehicleType, index) in vehicleTypes" :key="vehicleType.key" class="row items-center no-grow q-gutter-sm">
            <q-item>
              <q-item-section>
                <q-item-label>{{ vehicleType.label }}</q-item-label>
              </q-item-section>
              <q-item-section avatar>
                <q-toggle v-model="vehicleType.value" />
              </q-item-section>
            </q-item>
            <q-separator v-if="index < vehicleTypes.length - 1" vertical inset />
          </div>
        </div>
      </q-card>
    </div>
    <div>
      <m-group-title label="Taxi Providers" />
      <q-item-label caption style="margin-top: 0.5rem;">
        Enable/disable taxi providers for onward travel searches
      </q-item-label>

      <q-card bordered class="q-pa-md flex-card" style="margin-top: 1.5rem;">
        <div class="row items-center no-grow q-gutter-md">
          <div v-for="(provider, index) in providers" :key="provider.key" class="row items-center no-grow q-gutter-sm">
            <q-item>
              <q-item-section>
                <q-item-label>{{ provider.label }}</q-item-label>
              </q-item-section>
              <q-item-section avatar>
                <q-toggle v-model="provider.value" />
              </q-item-section>
            </q-item>
            <q-separator v-if="index < providers.length - 1" vertical inset />
          </div>
        </div>
      </q-card>
    </div>
    <div class="single-toggles">
      <div>
        <m-group-title label="SMS" />
        <q-item-label caption style="margin-top: 0.5rem;">
          Enable/disable SMS notifications
        </q-item-label>
      </div>
      <q-toggle v-model="sms_enabled" />
      <div>
        <m-group-title label="Search Result Logging" />
        <q-item-label caption style="margin-top: 0.5rem;">
          Enable/disable search result logging
        </q-item-label>
      </div>
      <q-toggle v-model="log_results" />
      <div>
        <m-group-title label="Automatic Rebooking" />
        <q-item-label caption style="margin-top: 0.5rem;">
          Enable/disable automatic rebooking
        </q-item-label>
      </div>
      <q-toggle v-model="auto_rebook_enabled" />
      <div>
        <m-group-title label="Support Contact" />
        <q-item-label caption style="margin-top: 0.5rem;">
          Email address for a support contact
        </q-item-label>
      </div>
      <q-input
        v-model="support_email"
        :error="$v.support_email.$invalid"
        placeholder="Enter a support contact"
        @blur="$v.support_email.$touch"
      >
        <template v-slot:error>
          <span v-if="!$v.support_email.$email && $v.support_email.$dirty">Please enter a valid e-mail address</span>
        </template>
      </q-input>
    </div>
    <q-separator />
    <div>
      <q-btn label="Save Config" color="primary" :disabled="$v.$invalid" @click="save" />
    </div>
  </div>
</template>
<script>
import { MGroupTitle } from 'components'
import store from 'store'
import { email } from 'vuelidate/lib/validators'
import i18n from 'i18n'
import loading from 'utils/loading'
import { getOnwardTravelConfig, updateOnwardTravelConfig } from 'api/organisations'
import { handleErrors } from 'utils/utils'
import { Notify } from 'quasar'

export default {
  name: 'OnwardTravelConfig',
  components: {
    MGroupTitle
  },
  data () {
    return {
      vehicleTypes: [
        { key: 'taxi', label: 'Taxi', value: false },
        { key: 'exec', label: 'Exec', value: false },
        { key: 'mpv', label: 'MPV', value: false },
        { key: 'wav', label: 'WAV', value: false },
        { key: 'other', label: 'Other', value: false }
      ],
      providers: [
        { key: 'cmac', label: 'CMAC', value: false },
        { key: 'autocab', label: 'Autocab', value: false },
        { key: 'karhoo', label: 'Karhoo', value: false },
        { key: 'uber', label: 'Uber', value: false }
      ],
      sms_enabled: false,
      log_results: false,
      auto_rebook_enabled: false,
      support_email: null
    }
  },
  computed: {
    orgId () {
      return this.$route.params.organisation
    }
  },
  validations: {
    support_email: { email }
  },
  async beforeMount () {
    const partner = store.getters.partner
    loading.start({
      message: i18n.t('loading.getting.onward_travel_config'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const { data } = await getOnwardTravelConfig(this.orgId)

      const { vehicle_groups, providers, sms_enabled, support_email, auto_rebook_enabled, log_results } = data

      this.vehicleTypes = this.vehicleTypes.map(vehicleType => {
        const { key } = vehicleType
        const value = vehicle_groups.includes(key)
        return { ...vehicleType, value }
      })

      this.providers = this.providers.map(provider => {
        const { key } = provider
        const value = providers.includes(key)
        return { ...provider, value }
      })

      this.sms_enabled = sms_enabled
      this.log_results = log_results
      this.support_email = support_email
      this.auto_rebook_enabled = auto_rebook_enabled

      loading.stop()
    } catch (err) {
      handleErrors(err)
      loading.stop()
    }
  },
  methods: {
    async save () {
      const partner = store.getters.partner

      loading.start({
        message: i18n.t('loading.updating.onward_travel_config'),
        partnerSlug: partner.slug,
        spinnerColor: partner.hex
      })

      try {
        await updateOnwardTravelConfig(this.orgId, {
          vehicle_groups: this.vehicleTypes.filter(vehicleType => vehicleType.value).map(vehicleType => vehicleType.key),
          providers: this.providers.filter(provider => provider.value).map(provider => provider.key),
          sms_enabled: this.sms_enabled,
          log_results: this.log_results,
          support_email: this.support_email || null,
          auto_rebook_enabled: this.auto_rebook_enabled

        })
        loading.stop()
        Notify.create({
          message: i18n.t('organisation.updated'),
          color: 'positive',
          icon: 'mdi-check'
        })
      } catch (err) {
        handleErrors(err)
        loading.stop()
      }
    }
  }
}
</script>

<style>
  .no-grow {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .flex-card {
    display: inline-flex;
    flex-direction: column;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .single-toggles{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 2rem;
    width: 50%;
  }
</style>
