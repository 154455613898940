<template>
  <div class="vehicle-hire-tariff row q-col-gutter-sm">
    <div class="col-xs-6 col-sm-5">
      <q-input
        v-model="label"
        readonly
        disable
        label="Tariff Label"
      />
    </div>
    <div class="col-xs-6 col-sm-5">
      <q-input
        v-model="code"
        readonly
        disable
        label="Tariff Code"
      />
    </div>
    <div class="col-xs-6 col-sm-1">
      <q-checkbox v-model="included" label="Included" class="checkbox" />
    </div>
    <div class="col-xs-6 col-sm-1">
      <q-btn round dense icon="delete" class="remove-tariff" @click="removeTariff" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['value'],
  computed: {
    label: {
      get () {
        return this.value.label
      },
      set (val) {
        this.$emit('input', { ...this.value, label: val })
      }
    },
    code: {
      get () {
        return this.value.code
      },
      set (val) {
        this.$emit('input', { ...this.value, code: val })
      }
    },
    included: {
      get () {
        return !!this.value.included
      },
      set (val) {
        this.$emit('input', { ...this.value, included: val })
      }
    }
  },
  methods: {
    removeTariff () {
      this.$emit('removeTariff')
    }
  }
}
</script>

<style lang="stylus" scoped>
.tariff-position
  display flex
  align-items center
  justify-content center
.remove-tariff
  margin-top 15px
.checkbox
  margin-top 20px

</style>
