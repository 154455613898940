<template>
  <q-page>
    <div class="row">
      <div v-if="resource" class="resource-info col-xs-12">
        <div class="top-sheet bg-primary">
          <div>
            <h4 class="top-sheet_title text-white">
              {{ resource.name }}
            </h4>
            <div class="top-sheet_subtitle text-white">
              Joined us on {{ resource.created_at.date | date }}
            </div>
          </div>
          <div class="quick-settings">
            <!-- TODO: Put more detailed versions of these settings into peek(s) -->

            <!-- Quick Settings: Block/Unblock -->
            <div class="quick-settings_block">
              <q-btn
                v-if="!resource.is_blocked"
                class="text-white"
                color="primary"
                round
                dense
                icon="block"
                @click.stop="block"
              >
                <q-tooltip> Block </q-tooltip>
              </q-btn>
              <q-btn
                v-if="resource.is_blocked"
                class="text-white"
                color="primary"
                round
                dense
                icon="check_circle_outline"
                @click.stop="unblock"
              >
                <q-tooltip> Unblock </q-tooltip>
              </q-btn>
            </div>

            <!-- Quick Settings: Pay on Account -->
            <div class="quick-settings_account">
              <q-btn
                :label="
                  resource.has_account
                    ? 'Disable account payment'
                    : 'Enable account payment'
                "
                class="text-white"
                color="primary"
                @click="setAccount"
              />
            </div>

            <!-- Quick Settings: Booking Auth Bypass -->
            <div>
              <q-btn
                label="Additional Settings"
                class="text-white"
                color="primary"
                @click="
                  $router.push({
                    hash: '/organisation',
                    query: { slug: resource.slug },
                  })
                "
              />
            </div>

            <!-- Quick Settings: Booking Auth Bypass -->
            <div>
              <q-btn
                label="Booking Auth Settings"
                class="text-white"
                color="primary"
                @click="
                  $router.push(`/organisations/${resource.slug}/booking-auth`)
                "
              />
            </div>
          </div>
        </div>
        <q-tabs align="justify" class="shadow-2 text-white bg-primary-tint">
          <q-route-tab to="profile" name="profile" icon="business">
            Profile
          </q-route-tab>
          <q-route-tab
            v-if="resource.attributes.content_config"
            to="contents"
            name="contents"
            icon="commute"
          >
            Contents
          </q-route-tab>
          <q-route-tab to="members" name="members" icon="person">
            Members
          </q-route-tab>
          <q-route-tab to="bookings" name="bookings" icon="receipt">
            Bookings
          </q-route-tab>
          <q-route-tab to="billing" name="billing" icon="payment">
            Pricing Schedule
          </q-route-tab>
          <q-route-tab to="notes" name="notes" icon="note">
            Notes
          </q-route-tab>
          <!-- <q-route-tab to="pricing"  name="pricing">Pricing</q-route-tab> -->
          <q-route-tab to="history" name="history" icon="history">
            History
          </q-route-tab>
          <q-route-tab v-if="resource.is_onward_travel" to="onward-travel" name="onward-travel" icon="local_taxi">
            Onward Travel
          </q-route-tab>
        </q-tabs>
        <router-view :key="$route.path" :resource="resource" />
      </div>
    </div>
  </q-page>
</template>
<script type="text/javascript">
import store from 'store'
import date from 'utils/date-time'
export default {
  name: 'OrganisationView',
  filters: {
    date (data) {
      return date.toShortDate(data)
    }
  },
  computed: {
    resource () {
      return this.$store.getters['organisations/getOrg'](this.$route.params.organisation)
    }
  },
  async beforeRouteEnter (to, from, next) {
    try {
      await store.dispatch('organisations/loadOrg', {
        orgId: to.params.organisation,
        params: {
          include: 'pricingschedule'
        }
      })
      await store.dispatch('organisations/loadRidehailingConfig', {
        orgId: to.params.organisation
      })
      next()
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    setAuthorisationRequired () {
      this.$store.dispatch('organisations/setAuthorisationRequired', {
        org: this.resource.slug,
        enabled: !this.resource.authorisation_enabled
      })
    },
    setAccount () {
      const action = this.resource.has_account ? 'disable' : 'enable'
      const string = this.resource.has_account ? 'Disable account' : 'Enable account'
      this.$q.dialog({
        title: string,
        cancel: true,
        color: 'secondary'
      }).onOk(data => {
        this.$store.dispatch('organisations/setAccount', {
          org: this.resource.slug,
          action
        })
      })
    },
    unblock () {
      this.$q.dialog({
        title: 'Confirm',
        message: 'Are you sure you want to unblock this organisation',
        ok: 'Confirm',
        cancel: 'Cancel'
      })
        .onOk(() => {
          this.$store.dispatch('organisations/unblock', this.resource.slug)
            .then(() => {
              this.$q.notify({
                message: `Organisation unblocked`,
                color: 'positive',
                icon: 'mdi-check'
              })
            })
            .catch(e => {
              this.$q.notify({
                message: e.data.title,
                color: 'negative',
                icon: 'clear'
              })
            })
        })
        .catch(() => { })
    },
    block () {
      this.$q.dialog({
        title: 'Confirm',
        message: 'Are you sure you want to block this organisation',
        ok: 'Confirm',
        cancel: 'Cancel'
      })
        .onOk(() => {
          this.$store.dispatch('organisations/block', this.resource.slug)
            .then(() => {
              this.$q.notify({
                message: `Organisation blocked`,
                color: 'positive',
                icon: 'mdi-check'
              })
            })
            .catch(e => {
              this.$q.notify({
                message: e.data.title,
                color: 'negative',
                icon: 'clear'
              })
            })
        })
        .catch(() => { })
    }
  }
}
</script>

<style lang="stylus" scoped>
.avatar
  margin-right 15px

h4, h6
  display inline-block
  vertical-align middle

h6
  text-transform uppercase

.user-meta
  margin-left 70px

.resource-info-list
  width 30%
  position relative
  z-index 200

.top-sheet
  display grid
  grid-template-columns 1fr max-content
  padding 1.6rem 2rem

  .quick-settings
    background-color rgba(0, 0, 0, .1)
    display grid
    grid-template-columns min-content 1fr 1fr
    gap .5rem
    padding .5rem
    border-radius 4px

    *
      display grid
      align-items center

    .quick-settings_block
      grid-row span 2

    .quick-settings_account
      grid-column span 2

.q-tabs
  position relative
  z-index 100

.tab-body
  height calc(100vh - 233px)
  overflow auto
  z-index 50
  position relative
</style>
